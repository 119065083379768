<template>
  <div>
    <div class="pb-9 pt-5 d-flex justify-center">
      <h1>Tags</h1>
    </div>
    <v-row>
      <!-- Linke Spalte für Tags erstellen etc. -->
      <v-col cols="6" md="3">
        <v-form ref="form" v-model="valid" :lazy-validation="true">
          <div class="pt-3 pb-9">
            <!-- Card für Tag hinzufügen -->
            <v-card>
              <div class="pa-5">
                <h3 class="d-flex justify-center">Neuer Tag</h3>
                <v-text-field
                  v-model="neuerGerichttyp.Name"
                  label="Name"
                  required
                  :rules="nameRules"
                ></v-text-field>
                <div class="pt-3 text-center">
                  <v-btn @click="createGerichttyp" outlined fab small>
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </div>
              </div>
            </v-card>
          </div>
        </v-form>
        <v-form ref="form2" v-model="valid2" :lazy-validation="true">
          <div class="pb-10">
            <v-card>
              <div class="pa-5">
                <h3 class="d-flex justify-center">Ausgewählter Tag</h3>
                <v-text-field
                  v-model="selectedGerichttyp.Name"
                  label="Name"
                  required
                  :rules="nameRules"
                ></v-text-field>
                <div class="d-flex justify-center">
                  <v-btn
                    class="mt-3 mr-5"
                    @click="updateGerichttyp"
                    outlined
                    fab
                    small
                  >
                    <v-icon>mdi-check</v-icon>
                  </v-btn>

                  <v-dialog v-model="dialog" width="500">
                    <template v-slot:activator="{ on, attrs }">
                      <div class="py-3">
                        <v-btn
                          outlined
                          color="error"
                          fab
                          small
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <v-card>
                      <v-card-title class="headline grey lighten-2">
                        Wirklich löschen?
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="dialog = false">
                          Nein
                        </v-btn>
                        <v-btn color="primary" text @click="deleteGerichttyp">
                          Ja
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </div>
            </v-card>
          </div>
        </v-form>
      </v-col>

      <v-col cols="12" md="9">
        <div>
          <v-radio-group v-model="selectedGerichttyp">
            <v-treeview open-on-click dense hoverable :items="alleGerichttypen">
              <template v-slot:label="{ item }">
                <div>{{ item.Name }} - {{ item.id }}</div>
              </template>
              <template v-slot:prepend="{ item }">
                <v-radio :value="item"></v-radio>
              </template>
            </v-treeview>
          </v-radio-group>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import http from "../http-common";
export default {
  name: "Gerichttyp",
  data() {
    return {
      nameRules: [(v) => !!v || "Darf nicht leer sein."],
      valid: true,
      valid2: true,
      alleGerichttypen: [],
      selectedGerichttyp: {},
      neuerGerichttyp: {},
      dialog: false,
    };
  },
  methods: {
    getAllGerichttypen() {
      http.get("/gerichttyp").then((response) => {
        this.alleGerichttypen = response.data;
        document.title = "Tags";
      });
    },
    createGerichttyp() {
      if (this.$refs.form.validate()) {
        http
          .post("/gerichttyp", this.neuerGerichttyp)
          .then(() => {
            this.getAllGerichttypen();
            this.neuerGerichttyp = {};
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    updateGerichttyp() {
      if (this.$refs.form2.validate()) {
        http
          .put(
            `/gerichttyp/${this.selectedGerichttyp.id}`,
            this.selectedGerichttyp
          )
          .then(() => {
            this.getAllGerichttypen();
            this.selectedGerichttyp = {};
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    deleteGerichttyp() {
      this.dialog = false;
      http
        .delete(`/gerichttyp/${this.selectedGerichttyp.id}`)
        .then(() => {
          this.getAllGerichttypen();
          this.selectedGerichttyp = {};
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getAllGerichttypen();
  },
};
</script>