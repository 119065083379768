<template>
  <div>
    <v-app-bar>
      <v-toolbar-title>Adrian Göß</v-toolbar-title>
    </v-app-bar>
    <v-main style="padding-bottom: 9rem">
      <v-container>
        <v-row class="pt-10">
          <v-col cols="5">
            <div class="d-flex flex-column align-center">
              <v-sheet width="250px" elevation="4">
                <v-img
                  :src="require('../assets/adrian.jpg')"
                  alt="Adrian_Goess"
                ></v-img>
              </v-sheet>
              <h2 class="mt-4">Adrian Göß</h2>
              <p class="mt-4">Doktorand</p>
              <v-btn
                target="_blank"
                href="https://en.www.math.fau.de/department/"
                text
                color="primary"
                >FAU Erlangen-Nürnberg</v-btn
              >
              <v-row>
                <v-btn
                  target="_blank"
                  href="https://www.linkedin.com/in/adrian-g%C3%B6%C3%9F-0a5b27175/"
                  class="ma-2"
                  color="primary"
                  icon
                >
                  <v-icon>mdi-linkedin</v-icon>
                </v-btn>
                <v-btn
                  target="_blank"
                  href="https://www.instagram.com/adrian_goess/"
                  class="ma-2"
                  color="primary"
                  icon
                >
                  <v-icon>mdi-instagram</v-icon>
                </v-btn>
                <v-btn
                  target="_blank"
                  href="mailto:adrian.goess@fau.de"
                  class="ma-2"
                  color="primary"
                  icon
                >
                  <v-icon>mdi-email</v-icon>
                </v-btn>
              </v-row>
            </div>
          </v-col>

          <v-col cols="7">
            <h2>Quick Info</h2>
            <p class="mt-4">
              I am an Assistant Professor of Mathematics at
              <a href="https://www.fau.eu/"
                >Friedrich-Alexander-Universität Erlangen-Nürnberg (FAU)</a
              >. I am affiliated with the newly established department of Data
              Science (DDS) and the
              <a href="https://en.www.math.fau.de/edom/"
                >Economics, Discrete Optimization, and Mathematics (EDOM)</a
              >
              group.
            </p>
            <p>
              Before coming to FAU I was an “Akademischer Rat” at the Chair of
              Operations Research of TU Munich, and a postdoc at the Chair of
              Algorithms & Complexity of TU Munich and the Economics and
              Computation group of University of Liverpool.
            </p>
            <p>
              I did my DPhil (aka PhD) at the Computer Science department of the
              University of Oxford, where I was fortunate to be advised by Elias
              Koutsoupias. I was also a member of St Anne’s College. I hold an
              undergraduate degree in Mathematics and an MSc in Logic,
              Algorithms and Computation (MPLA), both from the University of
              Athens.
            </p>

            <h2>Research Interests</h2>
            <p>
              My interests lie in the general field of Algorithms, Complexity
              and Optimization. I have primarily worked in algorithmic game
              theory, as well as online and approximation algorithms.
            </p>
          </v-col>
        </v-row>

        <v-row class="pa-6 mt-10" style="background-color: #E0E0E0">
          <v-col>
            <h2 class="mt-1">Working Papers</h2>
            <ul class="mt-2">
              <li>
                <h4>
                  Norm-induced Cuts: Optimization with Lipschitzian Black-box
                  Functions <a href="">[arXiv] [bibtex]</a>
                </h4>
                <p>
                  Adrian Göß, Alexander Martin, Sebastian Pokutta, Kartikey
                  Sharma arXiv: 2211.07547, March 2023
                </p>
              </li>
              <li>
                <h4>
                  Norm-induced Cuts: Optimization with Lipschitzian Black-box
                  Functions <a href="">[arXiv] [bibtex]</a>
                </h4>
                <p>
                  Adrian Göß, Alexander Martin, Sebastian Pokutta, Kartikey
                  Sharma arXiv: 2211.07547, March 2023
                </p>
              </li>
            </ul>

            <h2>Publications</h2>

            <ul class="mt-2">
              <li>
                <h4>
                  Norm-induced Cuts: Optimization with Lipschitzian Black-box
                  Functions <a href="">[arXiv] [bibtex]</a>
                </h4>
                <p>
                  Adrian Göß, Alexander Martin, Sebastian Pokutta, Kartikey
                  Sharma arXiv: 2211.07547, March 2023
                </p>
              </li>
              <li>
                <h4>
                  Norm-induced Cuts: Optimization with Lipschitzian Black-box
                  Functions <a href="">[arXiv] [bibtex]</a>
                </h4>
                <p>
                  Adrian Göß, Alexander Martin, Sebastian Pokutta, Kartikey
                  Sharma arXiv: 2211.07547, March 2023
                </p>
              </li>
              <li>
                <h4>
                  Norm-induced Cuts: Optimization with Lipschitzian Black-box
                  Functions <a href="">[arXiv] [bibtex]</a>
                </h4>
                <p>
                  Adrian Göß, Alexander Martin, Sebastian Pokutta, Kartikey
                  Sharma arXiv: 2211.07547, March 2023
                </p>
              </li>
            </ul>
          </v-col>
        </v-row>

        <v-row class="pa-6 mt-10">
          <v-col>
            <h2>Teaching</h2>
            <ul class="mt-2">
              <li>
                <h4>
                  Discrete Optimization II [FAU, SS23]
                </h4>
                <p>
                  Adrian Göß, Alexander Martin
                </p>
              </li>
              <li>
                <h4>
                  Discrete Optimization III [FAU, WS22/23]
                </h4>
                <p>
                  Adrian Göß, Alexander Martin
                </p>
              </li>
            </ul>
          </v-col>
        </v-row>

        <v-row class="pa-6 mt-10" style="background-color: #E0E0E0">
          <v-col>
            <h2 class="mt-1">Contact</h2>
            <p class="mt-3">
              <v-icon class="mr-2">mdi-email</v-icon>adrian.geoss@fau.de
            </p>
            <p class="mt-3">
              <v-icon class="mr-2">mdi-map-marker</v-icon>Room 03.382,
              Cauerstraße 11, 91058 Erlangen
            </p>
            <p class="mt-3">
              <v-icon class="mr-2">mdi-calendar-check</v-icon>send email for
              appointment
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <Footer />
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
export default {
  components: {
    Footer,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>