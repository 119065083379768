<template>
  <div>
    <Header />
    <v-main style="padding-bottom: 9rem">
      <v-container
        fluid
        v-if="$vuetify.breakpoint.xl"
      >
      <v-row>
        <v-col class="pl-10" cols="9">
        <EinzelnesRezept />
      </v-col>
      <v-col class="mt-10" cols="3">
          <Sidebar/>
        </v-col>
      </v-row>
      </v-container>
      <v-container
      fluid
        v-if="
          $vuetify.breakpoint.sm ||
          $vuetify.breakpoint.md ||
          $vuetify.breakpoint.lg
        "
      >
      <v-row>
        <v-col cols="8" lg="8" md="8" sm="12"> 
          <EinzelnesRezept />
        </v-col>
        <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="4" lg="4" md="4">
          <Sidebar/>
        </v-col>
      </v-row>
      </v-container>
      <v-container v-if="$vuetify.breakpoint.xs">
        <EinzelnesRezeptMobile />
      </v-container>
    </v-main>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import Sidebar from "../components/Sidebar.vue";
import EinzelnesRezept from "../components/EinzelnesRezept.vue";
import EinzelnesRezeptMobile from "../components/EinzelnesRezeptMobile.vue";
export default {
  name: "SingleRecipe",
  components: {
    Header,
    Footer,
    Sidebar,
    EinzelnesRezept,
    EinzelnesRezeptMobile,
  },
};
</script>