<template>
  <div>
    <v-card>
      <v-card-text>
        <v-form ref="form">
          <v-file-input v-model="imageFile" truncate-length="12"></v-file-input>
        </v-form>
        <span>{{ errmsg }}</span>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="gray" fab icon @click="save">
          <v-icon> mdi-content-save </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import http from "../http-common";
export default {
  name: "ImageUpload",
  props: ["RezeptId"],
  data() {
    return {
      imageFile: {},
      image: {},
      errmsg: ""
    };
  },
  methods: {
    save() {
      console.log(this.imageFile.size)
      if (this.imageFile.name && this.imageFile.size < 1048576) {
        http.post(`/rezept/${this.RezeptId}/bild/`, this.image).then((res) => {
          var formData = new FormData();
          formData.append("file", this.imageFile);
          http
            .put(`/bild/${res.data.id}/upload`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(() => {
              this.$emit("save");
              this.imageFile = {};
              this.image = {};
              this.errmsg = ""
            });
        });
      } else {
        this.errmsg = "Bild auswählen. Bild darf maximal 1 MB haben."
      }
    },
  },
};
</script>
