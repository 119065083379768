<template>
  <v-container>
    <v-row align="center" v-if="$vuetify.breakpoint.xs">
      <v-col align="center" cols="3">
        <v-avatar class="ma-3" size="70">
          <v-img :src="require('../assets/portrait.jpg')"></v-img>
        </v-avatar>
      </v-col>

      <v-col align="left" cols="9">
        <div class="mt-2">
          Hi, ich bin Kristina! Willkommen auf meinem Food-Blog, lasst euch
          inspirieren!
        </div>
      </v-col>
    </v-row>
    <v-row class="mb-n9" v-if="$vuetify.breakpoint.xs">
      <v-col>
        <div
          href="https://www.instagram.com/salzigundkandiert/"
          target="_blank"
        >
          <div class="d-flex justify-center">
            <v-icon size="35"> mdi-instagram </v-icon>
          </div>
        </div>
      </v-col>
      <v-col>
        <div href="https://at.pinterest.com/rolsingkristina/" target="_blank">
          <div class="d-flex justify-center">
            <v-icon color="red" size="35"> mdi-pinterest </v-icon>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row class="mb-n10" align="center" v-if="$vuetify.breakpoint.smAndUp">
      <v-col align="center" cols="2">
        <v-avatar size="90">
          <v-img :src="require('../assets/portrait.jpg')"></v-img>
        </v-avatar>
      </v-col>

      <v-col>
        <v-card flat class="mt-5">
          <v-card-subtitle>
            Hi, ich bin Kristina! Willkommen auf meinem Food-Blog. Lasst euch
            gerne von den vielen Rezepten aus aller Welt inspirieren!
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col align="center">
        <v-card
          href="https://www.instagram.com/salzigundkandiert/"
          target="_blank"
          flat
          class="mt-8"
        >
          <div class="d-flex justify-center">
            <v-icon size="30"> mdi-instagram </v-icon>
          </div>
          <v-card-subtitle> Finde mich auf Instagram! </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col align="center">
        <v-card
          href="https://at.pinterest.com/rolsingkristina/"
          target="_blank"
          flat
          class="mt-8"
        >
          <div class="d-flex justify-center">
            <v-icon color="red" size="30"> mdi-pinterest </v-icon>
          </div>
          <v-card-subtitle> Finde mich auf Pinterest! </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Topbar",
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style>
.mdi-instagram {
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.mdi-pinterest {
  color: #d6249f;
}
</style>