export function getCurrentSort(realSort) {
  switch (realSort) {
    case "Name-A-Z":
      return "Name";
    case "Name-Z-A":
      return "Name";
    case "Erstelldatum-neu-alt":
      return "Erstelldatum";
    case "Erstelldatum-alt-neu":
      return "Erstelldatum";
    case "Zuletzt-gemacht-neu-alt":
      return "Zuletzt zubereitet";
    case "Zuletzt-gemacht-alt-neu":
      return "Zuletzt zubereitet";
  }
}

export function getCurrentArrow(realSort) {
  switch (realSort) {
    case "Name-A-Z":
      return true;
    case "Name-Z-A":
      return false;
    case "Erstelldatum-neu-alt":
      return true;
    case "Erstelldatum-alt-neu":
      return false;
    case "Zuletzt-gemacht-neu-alt":
      return true;
    case "Zuletzt-gemacht-alt-neu":
      return false;
  }
}

export function getRealSort(currentSort, sortArrow) {
  switch (currentSort) {
    case "Name":
      if (sortArrow) {
        return "Name-A-Z";
      } else {
        return "Name-Z-A";
      }
    case "Erstelldatum":
      if (sortArrow) {
        return "Erstelldatum-neu-alt";
      } else {
        return "Erstelldatum-alt-neu";
      }
    case "Zuletzt zubereitet":
      if (sortArrow) {
        return "Zuletzt-gemacht-neu-alt";
      } else {
        return "Zuletzt-gemacht-alt-neu";
      }
  }
}
