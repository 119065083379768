import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUtensils } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import colors from 'vuetify/lib/util/colors'
import Vuex from 'vuex'
import Vue2Editor from "vue2-editor";

Vue.use(Vuex)
Vue.use(Vuetify)
Vue.use(Vue2Editor);

//fontawesome stuff
library.add(faUtensils)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

const store = new Vuex.Store({
  state: {
    stichwort: "",
    tags: [],
    art: null,
    currentRegion: null,
    sort: "Zuletzt-gemacht-neu-alt",
    page: 1,
    drawer: false,
    itemsPerPage: 21,
  },
  mutations: {
    changeStichwort (state, stichwort) {
      state.stichwort = stichwort
    },
    changeTags (state, tags) {
      state.tags = tags
    },
    changeDrawer (state, bool) {
      state.drawer = bool
    },
    changeArt (state, art) {
      state.art = art
    },
    changeCurrentRegion (state, currentRegion) {
      state.currentRegion = currentRegion
    },
    changeSort (state, sort) {
      state.sort = sort
    },
    changePage (state, page) {
      state.page = page
    },
    changeItemsPerPage (state, itemsPerPage) {
      state.itemsPerPage = itemsPerPage
    }
  },
  getters: {
    stichwort: state => state.stichwort,
    tags: state => state.tags,
    art: state => state.art,
    drawer: state => state.drawer,
    currentRegion: state => state.currentRegion,
    sort: state => state.sort,
    page: state => state.page,
    itemsPerPage: state => state.itemsPerPage,
  }
})


const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#D6D2D5", // old "#79B4B7",
        secondary: "#D6D2D5",
        accent: "#5B577A",
        error: colors.red.lighten3,
        anchor: "#A6A2B5",
      },
      dark: {
        primary: colors.red.lighten3,
        error: colors.purple.accent4,
      },
    },
  },
}); 
 
new Vue({
  store: store,
  vuetify : vuetify,
  router,
  render: h => h(App),
}).$mount('#app')
