<template>
  <div>
    <v-form ref="form" v-model="valid" :lazy-validation="true">
      <div>
        <div class="py-2">
          <v-text-field
            v-model="howto.Name"
            label="Name"
            :rules="nameRules"
            required
          ></v-text-field>
        </div>
        <p>Brüche: ½ ⅓ ⅔ ¼ ¾ ⅕ ⅙</p>
        <vue-editor v-model="howto.Inhalt" />
      </div>
    </v-form>

    <v-dialog v-model="showTips" width="700">
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn icon @click="showTips = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text><HowToMarkdown /></v-card-text>
      </v-card>
    </v-dialog>

    <v-btn
    :x-large="$vuetify.breakpoint.lgAndUp"
      fab
      bottom
      color="primary"
      fixed
      elevation="5"
      right
      @click="saveHowto"
    >
      <v-icon> mdi-check </v-icon>
    </v-btn>
  </div>
</template>

<script>
import http from "../http-common";
export default {
  name: "NeuesKochwissen",
  data() {
    return {
      nameRules: [(v) => !!v || "Darf nicht leer sein."],
      valid: true,
      showTips: false,
      login: "",
      howto: {
        id: null,
        Name: "",
        Inhalt: "",
      },
    };
  },
  methods: {
    checkLogin() {
      http.get(`/auth/user`).then((response) => {
        if ("Meisterkoch" == response.data) {
          this.login = response.data;
        }
      });
    },
    saveHowto() {
      if (this.$refs.form.validate()) {
        if (this.howto.id == null) {
          http
            .post("/kochwissen", this.howto)
            .then((response) => {
              this.$router.push("/HowTo/" + response.data.id);
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          http
            .put(`/kochwissen/${this.howto.id}`, this.howto)
            .then(() => {
              this.$router.back();
            })
            .catch((e) => {
              console.log(e);
            });
        }
      }
    },
    findHowtoIfAny() {
      if (this.$route.query.HowToId) {
        http
          .get(`/kochwissen/${this.$route.query.HowToId}`)
          .then((response) => {
            this.howto = response.data;
            document.title = response.data.Name;
          });
      } else {
        document.title = "Neues How To";
      }
    },
  },
  mounted() {
    this.findHowtoIfAny();
    this.checkLogin();
  },
};
</script>