<template>
  <v-toolbar color="secondary">
    <span
      ><v-img
        class="mr-3 ml-5"
        width="30"
        :src="require('../assets/logo.png')"
    /></span>
    <v-toolbar-title style="cursor: pointer;" @click="$router.push('/')">
      <span> salzig & kandiert </span>
    </v-toolbar-title>
 
    <v-spacer></v-spacer>

    <v-btn
      v-if="'/HowTo' != $router.currentRoute.path && login"
      @click="$router.push('/HowTo')"
      text
      >notes</v-btn
    >

    <v-btn
      v-if="'/new' != $router.currentRoute.path && login"
      @click="$router.push('/new')"
      text
      >neu</v-btn
    >

    <v-form
      @submit.prevent
      v-if="'/' == $router.currentRoute.path"
      class="mt-7 hidden-xs-only"
    >
      <v-text-field
        outlined
        dense
        v-model="keyword"
        clearable
        :append-icon="'mdi-magnify'"
        @keyup.enter="updateKeyword"
        @click:append="updateKeyword"
        @click:clear="clear"
      >
      </v-text-field>
    </v-form>
  </v-toolbar>
</template>

<script>
import http from "../http-common";
export default {
  name: "Header",
  data() {
    return {
      keyword: "",
      login: "",
    };
  },
  watch: {
    "$store.state.stichwort": function () {
      this.keyword = this.$store.state.stichwort;
    },
  },
  methods: {
    checkLogin() {
      http.get(`/auth/user`).then((response) => {
        if ("Meisterkoch" == response.data) {
          this.login = response.data;
        }
      });
    },
    goTo(to) {
      this.$router.push(to);
    },
    updateKeyword() {
      if (this.keyword) {
        this.keyword = this.keyword.trim();
      }
      this.$store.commit("changeStichwort", this.keyword);
    },
    clear() {
      this.$store.commit("changeStichwort", "");
    },
    toHome() {
      if ("/" != this.$router.currentRoute.path) {
        this.$router.push("/");
      }
    },
  },
  mounted() {
    this.checkLogin();
  },
  async created() {
    this.keyword = this.$store.getters.stichwort;
  },
};
</script>
