<template>
  <div>
    <p v-if="err">{{ err }}</p>
    <div v-if="rezept">
      <v-speed-dial
        v-model="fab"
        fixed
        bottom
        right
        v-if="login"
        elevation="5"
        direction="top"
        transition="slide-y-reverse-transition"
      >
        <template v-slot:activator>
          <v-btn v-model="fab" color="primary" dark fab>
            <v-icon v-if="fab"> mdi-close </v-icon>
            <v-icon v-else> mdi-menu </v-icon>
          </v-btn>
        </template>
        <v-btn
          fab
          dark
          small
          color="green"
          @click="$router.push('/new?RezeptId=' + rezept.id)"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn @click="upload = true" fab dark small color="indigo">
          <v-icon>mdi-upload</v-icon>
        </v-btn>
        <v-btn @click="dialog = true" fab dark small color="red">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-speed-dial>

      <v-row class="pt-15 d-flex justify-center">
        <h1 style="word-break: normal; text-align: center" class="mx-4">
          {{ rezept.Name }} {{ !rezept.IsOnline ? "(Offline)" : "" }}
        </h1>
      </v-row>
      <v-row class="pb-10 d-flex justify-center">
        <v-rating
          v-if="rezept.Bewertung != 0"
          empty-icon="mdi-star-outline"
          full-icon="mdi-star"
          half-icon="mdi-star-half"
          hover
          small
          half-increments
          readonly
          length="5"
          v-model="rezept.Bewertung"
        ></v-rating>
      </v-row>

      <v-card
        tile
        class="mb-6 mt-2"
        v-if="titleImage != undefined"
        elevation="2"
      >
        <v-img :src="'/bild/' + titleImage.id + '/thumb'"></v-img>
      </v-card>
      <v-row class="justify-center">
        <span class="grey--text" v-if="rezept.ZubereitungsZeitAnzeige">
          {{ rezept.ZubereitungsZeitAnzeige }}
        </span>
        <v-divider
          v-if="rezept.Portionen"
          inset
          class="mx-4 mt-n1"
          vertical
        ></v-divider>
        <span class="grey--text" v-if="rezept.Portionen">
          {{ rezept.Portionen }}
        </span>
        <v-divider
          v-if="rezept.LetztesMalGemacht"
          inset
          class="mx-4 mt-n1"
          vertical
        ></v-divider>
        <span class="grey--text" v-if="rezept.LetztesMalGemacht">
          {{ rezept.LetztesMalGemacht }}
        </span>
      </v-row>
      <v-row class="justify-center">
        <span class="grey--text" v-if="rezept.Niveau">
          Niveau: {{ rezept.Niveau }}
        </span>
      </v-row>

      <v-row v-if="rezept.ZutatenAnzeige" class="justify-center">
        <div class="mt-8">
          <h2>Zutaten</h2>
        </div>
      </v-row>
      <v-row class="justify-center">
        <div class="pr-3 mx-1 px-5">
          <div v-html="rezept.ZutatenAnzeige"></div>
        </div>
      </v-row>

      <v-row v-if="rezept.tools" class="justify-center">
        <div class="mt-8">
          <h2>Utensilien</h2>
        </div>
      </v-row>
      <v-row class="justify-center">
        <div class="pb-3 pr-3 mx-1 px-5">
          <div v-html="rezept.tools"></div>
        </div>
      </v-row>

      <v-row class="justify-center" v-if="rezept.Zubereitung">
        <div>
          <h2>Zubereitung</h2>
        </div>
      </v-row>
      <v-row class="justify-center">
        <div class="pb-3 pr-3 mx-1 px-5">
          <div v-html="rezept.Zubereitung"></div>
        </div>
      </v-row>

      <div class="pa-1 mb-4">
        <v-chip-group column>
          <v-chip
            color="black"
            disabled
            outlined
            pill
            v-for="gerichttyp in rezept.Gerichttypen"
            :key="gerichttyp.id"
          >
            {{ gerichttyp.Name }}
          </v-chip>
        </v-chip-group>
      </div>

      <SingleRecipeImages
        @ladeBilder="ladeBilder"
        :bilder="this.bilder"
        :rezept="this.rezept"
      />
      <v-dialog v-model="upload" width="500">
        <v-card>
          <v-card-title>
            Bild hochladen
            <v-spacer></v-spacer>
            <v-btn icon @click="upload = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <ImageUpload @save="gespeichert" :RezeptId="rezept.id" />
        </v-card>
      </v-dialog>
    </div>

    <v-dialog v-model="dialog" width="300">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Wirklich löschen?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Nein </v-btn>
          <v-btn color="primary" text @click="deleteRezept"> Ja </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SingleRecipeImages from "@/components/SingleRecipeImages.vue";
import ImageUpload from "@/components/ImageUpload.vue";
import http from "../http-common";
export default {
  name: "EinzelnesRezeptMobile",
  components: {
    SingleRecipeImages,
    ImageUpload,
  },
  data() {
    return {
      rezept: null,
      upload: false,
      fab: false,
      dialog: false,
      bilder: [],
      titleImage: null,
      err: null,
      login: "",
    };
  },
  methods: {
    checkLogin() {
      http.get(`/auth/user`).then((response) => {
        if ("Meisterkoch" == response.data) {
          this.login = response.data;
        }
      });
    },
    ladeRezept() {
      http.get(`/rezept/${this.$route.params.id}`).then((res) => {
        this.rezept = res.data;
        this.formateDatum();
        this.err = null;
        document.title = this.rezept.Name;
      });
    },
    formateDatum() {
      if (this.rezept.LetztesMalGemacht != null) {
        var year = this.rezept.LetztesMalGemacht.substring(0, 4);
        var month = this.rezept.LetztesMalGemacht.substring(5, 7);
        var day = this.rezept.LetztesMalGemacht.substring(8, 10);
        this.rezept.LetztesMalGemacht = day + "." + month + "." + year;
      }
    },
    gespeichert() {
      this.ladeBilder();
      this.upload = false;
    },
    ladeBilder() {
      http.get(`/rezept/${this.$route.params.id}/bild`).then((res) => {
        this.bilder = res.data;
        this.titleImage = res.data.find((image) => image.isTitleImage == 1);
      });
    },
    deleteRezept() {
      this.dialog = false;
      if (this.rezept.id != null) {
        http
          .delete(`/rezept/${this.rezept.id}`)
          .then(() => {
            this.$router.push("/");
          })
      }
    }
  },
  mounted() {
    this.ladeRezept();
    this.ladeBilder();
    this.checkLogin();
  },
};
</script>