import Vue from "vue";
import Router from 'vue-router';
import EditTags from './views/EditTags';
import SingleRecipe from './views/SingleRecipe';
import Terms from './views/Terms';
import AllRecipes from './views/AllRecipes';
import HowToView from './views/HowToView';
import SingleHowTo from './views/SingleHowTo';
import NewHowTo from './views/NewHowTo';
import NewRecipe from './views/NewRecipe';
import Adi from './views/Adi';


Vue.use(Router);

export default new Router({
    routes: [
        {
            path: "/terms",
            component: Terms
        },
        {
            path: "/adi",
            component: Adi
        },
        {
            path: "/",
            component: AllRecipes
        },
        {
            path: "/HowTo",
            component: HowToView
        },
        {
            path: "/HowTo/new",
            component: NewHowTo
        },
        {
            path: "/HowTo/:id",
            component: SingleHowTo
        },
        {
            path: "/tags",
            component: EditTags
        },
        {
            path: "/new",
            component: NewRecipe,
            query: {
                RezeptId: ""
            }
        },
        {
            path: "/:id",
            component: SingleRecipe
        },
    ]
})