<template>
  <div>
    <v-row class="pb-6 pt-10">
      <v-col class="d-flex justify-center">
        <h1>How To</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6" lg="3">
        <v-form @submit.prevent>
          <v-text-field
            placeholder="Stichwortsuche"
            v-model="keyword"
            :append-outer-icon="'mdi-magnify'"
            @click:append-outer="getHowto"
            @keyup.enter="getHowto"
          >
          </v-text-field>
        </v-form>
      </v-col>
      <v-col class="mt-5">
        <span class="grey--text">Sortierung</span>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dark
              text
              small
              color="primary"
              class="ml-2"
              v-bind="attrs"
              v-on="on"
            >
              {{ currentSort }}
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(number, index) in sort"
              :key="index"
              @click="updateSort(number)"
            >
              <v-list-item-title>{{ number }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-if="kochwissen.length == 0">
        <p>Nichts gefunden.</p>
      </v-col>
      <v-col v-else>
        <v-list shaped>
          <v-list-item-group color="primary">
            <v-list-item
              v-for="(item, i) in kochwissen"
              :key="i"
              @click="$router.push('/HowTo/' + item.id)"
            >
              <v-list-item-content>
                <v-list-item-title
                  style="word-break: normal; text-align: left"
                  v-text="item.Name"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="pt-5 d-flex justify-center">
          <v-btn
            v-if="login"
            text
            color="grey"
            @click="$router.push('/HowTo/new')"
          >
            Artikel erstellen
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import http from "../http-common";
export default {
  name: "Kochwissen",
  data() {
    return {
      kochwissen: [],
      lastSeen: [],
      keyword: "",
      login: "",
      sort: [
        "Name-A-Z",
        "Name-Z-A",
        "Erstelldatum-neu-alt",
        "Erstelldatum-alt-neu",
        "Updated-neu-alt",
        "Updated-alt-neu",
      ],
      currentSort: "Updated-neu-alt",
    };
  },
  methods: {
    updateSort(number) {
      this.currentSort = number;
      this.getHowto();
    },
    getHowto() {
      var query = "sort=" + this.currentSort;
      if (this.keyword) {
        query += "&keyword=" + this.keyword;
      }
      http.get(`/kochwissen?${query}`).then((response) => {
        this.kochwissen = response.data;
      });
    },
    getLastSeen() {
      http.get(`/kochwissen?`).then((response) => {
        this.lastSeen = response.data.slice(0, 4);
      });
    },
    checkLogin() {
      http.get(`/auth/user`).then((response) => {
        if ("Meisterkoch" == response.data) {
          this.login = response.data;
        }
      });
    },
  },
  mounted() {
    this.getHowto();
    this.getLastSeen();
    this.checkLogin();
    document.title = "How To";
  },
};
</script>