<template>
  <v-footer absolute padless>
    <v-card
      flat
      tile
      width="100%"
      style="background: #9d9d9d"
      class="text-center"
    >
      <v-card-text>
        <div class="d-flex justify-center">
          <v-btn @click="$router.push('/')" class="mx-4" icon :key="'mdi-home'">
            <v-icon size="24px">
              {{ "mdi-home" }}
            </v-icon>
          </v-btn>
          <v-btn class="mx-4" v-if="proofLogin" icon to="/tags"
            ><v-icon>mdi-tag-text-outline</v-icon></v-btn
          >
          <v-btn class="mx-4" icon @click="showLogin = true">
            <v-icon size="24px">
              {{ "mdi-login-variant" }}
            </v-icon>
          </v-btn>
          <Login :visible="showLogin" @close="showLogin = false" />
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text>
        <v-btn to="/terms" class="mx-4" text small> Terms </v-btn>
        {{ new Date().getFullYear() }} — <strong>Kristina Rolsing</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
import http from "../http-common";
import Login from "./Login.vue";
export default {
  name: "Footer",
  components: {
    Login,
  },
  data() {
    return {
      showLogin: false,
      proofLogin: "",
    };
  },
  methods: {
    checkLogin() {
      http.get(`/auth/user`).then((response) => {
        if ("Meisterkoch" == response.data) {
          this.proofLogin = response.data;
        }
      });
    },
  },
  created() {
    this.checkLogin();
  },
};
</script>
