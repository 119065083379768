<template>
  <div data-app>
    <div id="app">
      <v-app class="schrift">
        <router-view />
      </v-app>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  watch: {
    $route(to, from) {
      document.title = "Salzig & Kandiert";
      window. scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');
.schrift {
  font-family: 'Quicksand', sans-serif;
}
</style>