<template>
  <div>
    <Header />
    <v-main style="padding-bottom: 9rem">
      <v-container>
        <NeuesRezept />
      </v-container>
    </v-main>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import NeuesRezept from "../components/NeuesRezept.vue";
export default {
  name: "Rezepte",
  components: {
    Header,
    Footer,
    NeuesRezept,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>