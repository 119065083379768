<template>
  <div>
    <v-row>
      <v-btn-toggle
        class="mb-4"
        v-model="art"
        borderless
        v-on:change="tagart()"
      >
        <v-btn text height="55" small>
          <div class="d-flex flex-column">
            herzhaft <v-icon color="grey">mdi-pasta</v-icon>
          </div>
        </v-btn>
        <v-btn text height="55" small>
          <div class="d-flex flex-column">
            süß <v-icon color="grey">mdi-cupcake</v-icon>
          </div>
        </v-btn>
        <v-btn text height="55" small>
          <div class="d-flex flex-column">
            getränk <v-icon color="grey">mdi-cup</v-icon>
          </div>
        </v-btn>
      </v-btn-toggle>
    </v-row>

    <v-row>
      <v-col cols="5">
        <v-btn-toggle borderless v-model="veganToggle">
          <v-btn text small @click="localUpdate(115)">
            <v-icon left> mdi-leaf </v-icon>
            <span>Vegan</span>
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col cols="7" v-if="art != 2">
        <v-btn-toggle borderless v-model="vegetarischToggle">
          <v-btn text small @click="localUpdate(116)">
            <v-icon left> mdi-sprout </v-icon>
            <span>Vegetarisch</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
    </v-row>

    <v-row v-if="art != 1 && art != 2">
      <v-col cols="5">
        <v-btn-toggle borderless v-model="fleischToggle">
          <v-btn text small @click="localUpdate(117)">
            <v-icon left> mdi-cow </v-icon>
            <span>Fleisch</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
      <v-col cols="7">
        <v-btn-toggle borderless v-model="fischToggle">
          <v-btn text small @click="localUpdate(150)">
            <v-icon left> mdi-fish </v-icon>
            <span>Fisch</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
    </v-row>

    <v-divider class="mt-4 mb-4"></v-divider>

    <v-row>
      <v-col cols="5">
        <v-btn-toggle borderless v-model="kaltToggle">
          <v-btn text small @click="localUpdate(34)">
            <v-icon left> mdi-snowflake </v-icon>
            <span>Kalt</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
      <v-col cols="7">
        <v-btn-toggle borderless v-model="warmToggle">
          <v-btn text small @click="localUpdate(33)">
            <v-icon left> mdi-fire </v-icon>
            <span>Warm</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
    </v-row>

    <v-divider class="mt-4 mb-4"></v-divider>

    <v-row v-if="art == 2">
      <v-col cols="5">
        <v-btn-toggle borderless v-model="cocktailToggle">
          <v-btn text small @click="localUpdate(93)">
            <v-icon left> mdi-glass-cocktail </v-icon>
            <span>Cocktail</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
      <v-col cols="7">
        <v-btn-toggle borderless v-model="smoothieToggle">
          <v-btn text small @click="localUpdate(99)">
            <v-icon left> mdi-beer </v-icon>
            <span>Smoothie</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
    </v-row>

    <v-row v-if="art == 1">
      <v-col cols="5">
        <v-btn-toggle borderless v-model="kuchenToggle">
          <v-btn text small @click="localUpdate(18)">
            <span>Kuchen</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
      <v-col cols="7">
        <v-btn-toggle borderless v-model="torteToggle">
          <v-btn text small @click="localUpdate(19)">
            <span>Torte</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
    </v-row>

    <v-row v-if="art == 1">
      <v-col cols="5">
        <v-btn-toggle borderless v-model="muffinToggle">
          <v-btn text small @click="localUpdate(21)">
            <span>Muffin</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
      <v-col cols="7">
        <v-btn-toggle borderless v-model="cupcakeToggle">
          <v-btn text small @click="localUpdate(20)">
            <span>Cupcake</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
    </v-row>

    <v-row v-if="art == 1">
      <v-col cols="5">
        <v-btn-toggle borderless v-model="pieToggle">
          <v-btn text small @click="localUpdate(22)">
            <span>Pie</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
      <v-col cols="7">
        <v-btn-toggle borderless v-model="tarteToggle">
          <v-btn text small @click="localUpdate(119)">
            <span>Tarte</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
    </v-row>

    <v-row v-if="art == 1">
      <v-col cols="5">
        <v-btn-toggle borderless v-model="kekseToggle">
          <v-btn text small @click="localUpdate(24)">
            <span>Kekse</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
      <v-col cols="7">
        <v-btn-toggle borderless v-model="hefeToggle">
          <v-btn text small @click="localUpdate(101)">
            <span>Hefegebäck</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
    </v-row>

    <v-row v-if="art == 1">
      <v-col cols="5">
        <v-btn-toggle borderless v-model="riegelToggle">
          <v-btn text small @click="localUpdate(25)">
            <span>Riegel</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
      <v-col cols="7">
        <v-btn-toggle borderless v-model="pralinenToggle">
          <v-btn text small @click="localUpdate(26)">
            <span>Pralinen</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
    </v-row>

    <v-row v-if="art == 1">
      <v-col cols="5">
        <v-btn-toggle borderless v-model="mousseToggle">
          <v-btn text small @click="localUpdate(102)">
            <span>Mousse</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
      <v-col cols="7">
        <v-btn-toggle borderless v-model="cremeToggle">
          <v-btn text small @click="localUpdate(28)">
            <span>Creme</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
    </v-row>

    <v-row v-if="art == 1">
      <v-col cols="5">
        <v-btn-toggle borderless v-model="dessertToggle">
          <v-btn text small @click="localUpdate(126)">
            <span>Dessert</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
      <v-col cols="7">
        <v-btn-toggle borderless v-model="crumbleToggle">
          <v-btn text small @click="localUpdate(100)">
            <span>Crumble</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
    </v-row>

    <v-row v-if="art == 1">
      <v-col cols="5">
        <v-btn-toggle borderless v-model="gefroneresToggle">
          <v-btn text small @click="localUpdate(23)">
            <span>Gefrorenes</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
      <v-col cols="7">
        <v-btn-toggle borderless v-model="dsauceToggle">
          <v-btn text small @click="localUpdate(136)">
            <span>Sauce</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
    </v-row>

    <v-row v-if="art != 1 && art != 2">
      <v-col cols="5">
        <v-btn-toggle borderless v-model="nudelToggle">
          <v-btn text small @click="localUpdate(129)">
            <span>Nudel</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
      <v-col cols="7">
        <v-btn-toggle borderless v-model="reisToggle">
          <v-btn text small @click="localUpdate(130)">
            <span>Reis</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
    </v-row>

    <v-row v-if="art != 1 && art != 2">
      <v-col cols="5">
        <v-btn-toggle borderless v-model="kartoffelToggle">
          <v-btn text small @click="localUpdate(131)">
            <span>Kartoffel</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
      <v-col cols="7">
        <v-btn-toggle borderless v-model="gemüseToggle">
          <v-btn text small @click="localUpdate(132)">
            <span>Gemüse</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
    </v-row>

    <v-row v-if="art != 1 && art != 2">
      <v-col cols="5">
        <v-btn-toggle borderless v-model="eiToggle">
          <v-btn text small @click="localUpdate(81)">
            <span>Ei</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
      <v-col cols="7">
        <v-btn-toggle borderless v-model="proteineToggle">
          <v-btn text small @click="localUpdate(140)">
            <span>Proteine</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
    </v-row>

    <v-row v-if="art != 1 && art != 2">
      <v-col cols="5">
        <v-btn-toggle borderless v-model="teigToggle">
          <v-btn text small @click="localUpdate(87)">
            <span>Teig</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
      <v-col cols="7">
        <v-btn-toggle borderless v-model="brotToggle">
          <v-btn text small @click="localUpdate(90)">
            <span>Brot</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
    </v-row>

    <v-divider v-if="art != 1 && art != 2" class="mt-4 mb-4"></v-divider>

    <v-row v-if="art != 1 && art != 2">
      <v-col cols="5">
        <v-btn-toggle borderless v-model="vorspeiseToggle">
          <v-btn text small @click="localUpdate(15)">
            <span class="text-left">Vorspeise</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
      <v-col cols="7">
        <v-btn-toggle borderless v-model="hauptspeiseToggle">
          <v-btn text small @click="localUpdate(9)">
            <span>Hauptspeise</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
    </v-row>

    <v-row v-if="art != 1 && art != 2">
      <v-col cols="5">
        <v-btn-toggle borderless v-model="frühstückToggle">
          <v-btn text small @click="localUpdate(8)">
            <span class="text-left">Frühstück</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
      <v-col cols="7">
        <v-btn-toggle borderless v-model="snackToggle">
          <v-btn text small @click="localUpdate(10)">
            <span>Snack</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
    </v-row>

    <v-row v-if="art != 1 && art != 2">
      <v-col cols="5">
        <v-btn-toggle borderless v-model="salatToggle">
          <v-btn text small @click="localUpdate(11)">
            <span class="text-left">Salat</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
      <v-col cols="7">
        <v-btn-toggle borderless v-model="beilageToggle">
          <v-btn text small @click="localUpdate(16)">
            <span>Beilage</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
    </v-row>

    <v-row v-if="art != 1 && art != 2">
      <v-col cols="5">
        <v-btn-toggle borderless v-model="sauceToggle">
          <v-btn text small @click="localUpdate(97)">
            <span class="text-left">Sauce</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
      <v-col cols="7">
        <v-btn-toggle borderless v-model="dipToggle">
          <v-btn text small @click="localUpdate(98)">
            <span>Dip</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
    </v-row>

    <v-row v-if="art != 1 && art != 2">
      <v-col cols="5">
        <v-btn-toggle borderless v-model="aufstrichToggle">
          <v-btn text small @click="localUpdate(35)">
            <span class="text-left">Aufstrich</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
      <v-col cols="7">
        <v-btn-toggle borderless v-model="dressingToggle">
          <v-btn text small @click="localUpdate(133)">
            <span>Dressing</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
    </v-row>

    <v-divider v-if="art != 1 && art != 2" class="mt-4 mb-4"></v-divider>

    <v-row v-if="art != 1 && art != 2">
      <v-col cols="5">
        <v-btn-toggle borderless v-model="pizzaToggle">
          <v-btn text small @click="localUpdate(70)">
            <span class="text-left">Pizza<br />Flammkuchen</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
      <v-col cols="7">
        <v-btn-toggle borderless v-model="auflaufToggle">
          <v-btn text small @click="localUpdate(72)">
            <span>Auflauf</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
    </v-row>

    <v-row v-if="art != 1 && art != 2">
      <v-col cols="5">
        <v-btn-toggle borderless v-model="quicheToggle">
          <v-btn text small @click="localUpdate(119)">
            <span class="text-left">Quiche<br />Tarte</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
      <v-col cols="7">
        <v-btn-toggle borderless v-model="lasagneToggle">
          <v-btn text small @click="localUpdate(73)">
            <span class="text-left">Lasagne<br />Cannelloni</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
    </v-row>

    <v-row v-if="art != 1 && art != 2">
      <v-col cols="5">
        <v-btn-toggle borderless v-model="bratenToggle">
          <v-btn text small @click="localUpdate(91)">
            <span class="text-left">Braten<br />Strudel</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
      <v-col cols="7">
        <v-btn-toggle borderless v-model="burgerToggle">
          <v-btn text small @click="localUpdate(78)">
            <span class="text-left">Burger<br />Sandwiches</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
    </v-row>

    <v-row v-if="art != 1 && art != 2">
      <v-col cols="5">
        <v-btn-toggle borderless v-model="suppeToggle">
          <v-btn text small @click="localUpdate(30)">
            <span class="text-left">Suppe<br />Eintopf</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
      <v-col cols="7">
        <v-btn-toggle borderless v-model="curryToggle">
          <v-btn text small @click="localUpdate(77)">
            <span class="text-left">Curry</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
    </v-row>

    <v-row v-if="art != 1 && art != 2">
      <v-col cols="5">
        <v-btn-toggle borderless v-model="crepesToggle">
          <v-btn text small @click="localUpdate(94)">
            <span class="text-left">Crêpes<br />Galettes</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
      <v-col cols="7">
        <v-btn-toggle borderless v-model="pancakeToggle">
          <v-btn text small @click="localUpdate(82)">
            <span class="text-left">Pancake<br />Waffel</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
    </v-row>

    <v-row v-if="art != 1 && art != 2">
      <v-col cols="5">
        <v-btn-toggle borderless v-model="frikadelleToggle">
          <v-btn text small @click="localUpdate(83)">
            <span class="text-left">Frikadelle<br />Bratling</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
      <v-col cols="7">
        <v-btn-toggle borderless v-model="schnitzelToggle">
          <v-btn text small @click="localUpdate(84)">
            <span class="text-left">Schnitzel<br />Steak</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
    </v-row>

    <v-row v-if="art != 1 && art != 2">
      <v-col cols="5">
        <v-btn-toggle borderless v-model="wrapToggle">
          <v-btn text small @click="localUpdate(88)">
            <span class="text-left">Wrap<br />Taco</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
      <v-col cols="7">
        <v-btn-toggle borderless v-model="sushiToggle">
          <v-btn text small @click="localUpdate(86)">
            <span class="text-left">Sushi</span>
          </v-btn></v-btn-toggle
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import http from "../http-common";
export default {
  name: "FilterButtons",
  data() {
    return {
      tags: [],
      selectedTags: [],
      art: null,
    };
  },
  computed: {
    veganToggle: {
      set() {},
      get() {
        return this.selectedTags.includes(115) ? 0 : -1;
      },
    },
    vegetarischToggle: {
      set() {},
      get() {
        return this.selectedTags.includes(116) ? 0 : -1;
      },
    },
    fleischToggle: {
      set() {},
      get() {
        return this.selectedTags.includes(117) ? 0 : -1;
      },
    },
    fischToggle: {
      set() {},
      get() {
        return this.selectedTags.includes(150) ? 0 : -1;
      },
    },
    kaltToggle: {
      set() {},
      get() {
        return this.selectedTags.includes(34) ? 0 : -1;
      },
    },
    warmToggle: {
      set() {},
      get() {
        return this.selectedTags.includes(33) ? 0 : -1;
      },
    },
    cocktailToggle: {
      set() {},
      get() {
        return this.selectedTags.includes(93) ? 0 : -1;
      },
    },
    smoothieToggle: {
      set() {},
      get() {
        return this.selectedTags.includes(99) ? 0 : -1;
      },
    },
    kuchenToggle: {
      set() {},
      get() {
        return this.selectedTags.includes(18) ? 0 : -1;
      },
    },
    torteToggle() {
      return this.selectedTags.includes(19) ? 0 : -1;
    },
    muffinToggle() {
      return this.selectedTags.includes(21) ? 0 : -1;
    },
    cupcakeToggle() {
      return this.selectedTags.includes(20) ? 0 : -1;
    },
    pieToggle() {
      return this.selectedTags.includes(22) ? 0 : -1;
    },
    tarteToggle() {
      return this.selectedTags.includes(119) ? 0 : -1;
    },
    kekseToggle() {
      return this.selectedTags.includes(24) ? 0 : -1;
    },
    hefeToggle() {
      return this.selectedTags.includes(101) ? 0 : -1;
    },
    riegelToggle() {
      return this.selectedTags.includes(25) ? 0 : -1;
    },
    pralinenToggle() {
      return this.selectedTags.includes(26) ? 0 : -1;
    },
    mousseToggle() {
      return this.selectedTags.includes(102) ? 0 : -1;
    },
    cremeToggle() {
      return this.selectedTags.includes(28) ? 0 : -1;
    },
    dessertToggle() {
      return this.selectedTags.includes(126) ? 0 : -1;
    },
    crumbleToggle() {
      return this.selectedTags.includes(100) ? 0 : -1;
    },
    gefrorenesToggle() {
      return this.selectedTags.includes(23) ? 0 : -1;
    },
    dsauceToggle() {
      return this.selectedTags.includes(136) ? 0 : -1;
    },
    nudelToggle() {
      return this.selectedTags.includes(129) ? 0 : -1;
    },
    reisToggle() {
      return this.selectedTags.includes(130) ? 0 : -1;
    },
    kartoffelToggle() {
      return this.selectedTags.includes(131) ? 0 : -1;
    },
    gemüseToggle() {
      return this.selectedTags.includes(132) ? 0 : -1;
    },
    eiToggle() {
      return this.selectedTags.includes(81) ? 0 : -1;
    },
    proteineToggle() {
      return this.selectedTags.includes(140) ? 0 : -1;
    },
    teigToggle() {
      return this.selectedTags.includes(87) ? 0 : -1;
    },
    brotToggle() {
      return this.selectedTags.includes(90) ? 0 : -1;
    },
    vorspeiseToggle() {
      return this.selectedTags.includes(15) ? 0 : -1;
    },
    hauptspeiseToggle() {
      return this.selectedTags.includes(9) ? 0 : -1;
    },
    frühstückToggle() {
      return this.selectedTags.includes(8) ? 0 : -1;
    },
    snackToggle() {
      return this.selectedTags.includes(10) ? 0 : -1;
    },
    salatToggle() {
      return this.selectedTags.includes(11) ? 0 : -1;
    },
    beilageToggle() {
      return this.selectedTags.includes(16) ? 0 : -1;
    },
    sauceToggle() {
      return this.selectedTags.includes(97) ? 0 : -1;
    },
    dipToggle() {
      return this.selectedTags.includes(98) ? 0 : -1;
    },
    aufstrichToggle() {
      return this.selectedTags.includes(35) ? 0 : -1;
    },
    pizzaToggle() {
      return this.selectedTags.includes(70) ? 0 : -1;
    },
    auflaufToggle() {
      return this.selectedTags.includes(72) ? 0 : -1;
    },
    quicheToggle() {
      return this.selectedTags.includes(92) ? 0 : -1;
    },
    lasagneToggle() {
      return this.selectedTags.includes(73) ? 0 : -1;
    },
    bratenToggle() {
      return this.selectedTags.includes(91) ? 0 : -1;
    },
    burgerToggle() {
      return this.selectedTags.includes(78) ? 0 : -1;
    },
    suppeToggle() {
      return this.selectedTags.includes(30) ? 0 : -1;
    },
    curryToggle() {
      return this.selectedTags.includes(77) ? 0 : -1;
    },
    crepesToggle() {
      return this.selectedTags.includes(94) ? 0 : -1;
    },
    pancakeToggle() {
      return this.selectedTags.includes(82) ? 0 : -1;
    },
    dressingToggle() {
      return this.selectedTags.includes(133) ? 0 : -1;
    },
    frikadelleToggle() {
      return this.selectedTags.includes(83) ? 0 : -1;
    },
    schnitzelToggle() {
      return this.selectedTags.includes(84) ? 0 : -1;
    },
    wrapToggle() {
      return this.selectedTags.includes(88) ? 0 : -1;
    },
    sushiToggle() {
      return this.selectedTags.includes(86) ? 0 : -1;
    },
  },
  watch: {
    "$store.state.tags": function () {
      this.selectedTags = this.$store.state.tags;
      if (this.selectedTags.length < 1) {
        this.$store.commit("changeArt", null);
      }
    },
    "$store.state.art": function () {
      this.art = this.$store.state.art;
    }
  },
  methods: {
    tagart() {
      var index = undefined;
      this.$store.commit("changeArt", this.art);
      if (this.art == 0) {
        this.selectedTags.push(151);
        this.herzhaftSelected();
        this.updateTags();
      } else if (this.art == 1) {
        this.selectedTags.push(152);
        index = this.selectedTags.indexOf(151);
        if (index > -1) {
          this.selectedTags.splice(index, 1);
        }
        index = this.selectedTags.indexOf(153);
        if (index > -1) {
          this.selectedTags.splice(index, 1);
        }
        this.updateTags();
      } else if (this.art == 2) {
        this.selectedTags.push(153);
        index = this.selectedTags.indexOf(152);
        if (index > -1) {
          this.selectedTags.splice(index, 1);
        }
        index = this.selectedTags.indexOf(151);
        if (index > -1) {
          this.selectedTags.splice(index, 1);
        }
        this.updateTags();
      } else {
        index = this.selectedTags.indexOf(151);
        if (index > -1) {
          this.selectedTags.splice(index, 1);
        }
        index = this.selectedTags.indexOf(152);
        if (index > -1) {
          this.selectedTags.splice(index, 1);
        }
        index = this.selectedTags.indexOf(153);
        if (index > -1) {
          this.selectedTags.splice(index, 1);
        }
      }
    },
    localUpdate(id) {
      if (this.selectedTags.includes(id)) {
        const index = this.selectedTags.indexOf(id);
        this.selectedTags.splice(index, 1);
        this.updateTags();
      } else {
        this.selectedTags.push(id);
        this.updateTags();
      }
    },
    updateTags() {
      this.$store.commit("changeTags", this.selectedTags);
    },
    getAllGerichttypen() {
      http.get("/gerichttyp").then((response) => {
        this.nurHerzhadt = response.data.filter(a => a.Art == 0).map(a => a.id);
        this.nurSüß = response.data.filter(a => a.Art == 1).map(a => a.id);
        this.nurGetränk = response.data.filter(a => a.Art == 2).map(a => a.id);
      });
    },
    herzhaftSelected() {//noch nicht fertig....
        if (this.selectedTags.indexOf(152) > -1) {
          this.selectedTags.splice(this.selectedTags.indexOf(152), 1);
        }
        if (this.selectedTags.indexOf(153) > -1) {
          this.selectedTags.splice(this.selectedTags.indexOf(153), 1);
        }
    }
  },
  mounted() {
    this.getAllGerichttypen();
  },
  async created() {
    this.selectedTags = this.$store.getters.tags;
    this.art = this.$store.getters.art;
  },
};
</script>