<template>
  <div class="mt-3">
    <v-row>
      <v-col>
        <v-row v-if="$vuetify.breakpoint.xs">
          <v-col cols="12">
            <v-form class="mt-5 mb-n5" @submit.prevent>
              <v-text-field
                outlined
                dense
                placeholder="Stichwortsuche"
                v-model="keyword"
                clearable
                :append-icon="'mdi-magnify'"
                @keyup.enter="updateKeyword"
                @click:append-outer="updateKeyword"
                @click:clear="clear"
              >
              </v-text-field>
            </v-form>
          </v-col>
        </v-row>

        <v-row class="mt-2 mb-3 d-flex align-center" v-if="tags.length > 0">
            <v-btn class="mr-4" small text outlined @click="clearFilter()">Clear</v-btn>
            <v-chip-group column>
              <v-fade-transition group>
                
                <v-chip
                  color="#79B4B7"
                  v-for="(item, index) in tags"
                  :key="index"
                >
                  {{ tagMap.get(item) }}
                </v-chip>
              </v-fade-transition>
            </v-chip-group>
        </v-row>

        <v-data-iterator
          v-if="rezepte.length > 0"
          :items="rezepte"
          :items-per-page="itemsPerPage"
          :page="page"
          hide-default-footer
        >
          <template v-slot:header>
            <v-row>
              <v-col class="mt-4 d-flex justify-begin" cols="6">
                <span class="grey--text">Gefunden: {{ count }}</span>
              </v-col>
              <v-col class="mt-4 d-flex justify-end" cols="6">
                <span v-if="numberOfPages > 1" class="mr-4 grey--text">
                  {{ page }}/{{ numberOfPages }}
                </span>
                <v-btn
                  icon
                  small
                  :disabled="numberOfPages <= 1 || page == 1"
                  class="mr-1"
                  @click="formerPage"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn
                  :disabled="numberOfPages <= 1 || page == numberOfPages"
                  icon
                  small
                  class="ml-1"
                  @click="nextPage"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </template>

          <template v-slot:default="{ items }">
            <v-row>
              <v-col
                v-for="item in items"
                :key="item.Name"
                cols="12"
                sm="6"
                md="6"
                lg="4"
              >
                <RecipeCard :recipe="item" />
              </v-col>
            </v-row>
          </template>

          <template v-slot:footer>
            <v-row class="mt-2" align="center" justify="end">
              <span v-if="numberOfPages > 1" class="mr-4 grey--text">
                {{ page }}/{{ numberOfPages }}
              </span>
              <v-btn
                :disabled="numberOfPages <= 1 || page == 1"
                icon
                small
                class="mr-1"
                @click="formerPage"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                :disabled="numberOfPages <= 1 || page == numberOfPages"
                icon
                small
                class="ml-1"
                @click="nextPage"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-row>
          </template>
        </v-data-iterator>
      </v-col>
    </v-row>
    <v-btn
      fab
      bottom
      color="primary"
      fixed
      v-if="!drawer"
      elevation="5"
      right
      :class="[
        '/' != this.$router.currentRoute.path ? 'd-none' : 'hidden-md-and-up',
      ]"
      @click.stop="$store.commit('changeDrawer', true)"
    >
      <v-icon> mdi-filter-variant </v-icon>
    </v-btn>
    <v-btn
      fab
      v-if="drawer"
      bottom
      color="primary"
      fixed
      style="z-index: 100"
      elevation="4"
      right
      @click="$store.commit('changeDrawer', false)"
    >
      <v-icon> mdi-check </v-icon>
    </v-btn>
  </div>
</template>

<style>
/* This is for documentation purposes and will not be needed in your application */
#lateral .v-btn--example {
  bottom: 0;
  position: absolute;
  margin: 0 0 16px 16px;
}
</style>

<script>
import http from "../http-common";
import RecipeCard from "@/components/RecipeCard.vue";
export default {
  name: "RezepteAnzeigen",
  components: {
    RecipeCard,
  },
  data() {
    return {
      search: "",
      filter: {},
      page: 1,
      itemsPerPage: 0,
      count: 0,
      tagMap: null,
      drawer: this.$store.state.drawer,
      rezepte: [],
      tags: this.$store.state.tags,
      keyword: "",
      sort: "",
    };
  },
  watch: {
    "$store.state.tags": function () {
      this.tags = this.$store.state.tags;
      this.page = 1;
      this.updateRezepte();
    },
    "$store.state.stichwort": function () {
      this.keyword = this.$store.state.stichwort;
      this.page = 1;
      this.updateRezepte();
    },
    "$store.state.drawer": function () {
      this.drawer = this.$store.state.drawer;
    },
    "$store.state.itemsPerPage": function () {
      this.itemsPerPage = this.$store.state.itemsPerPage;
      this.page = 1;
      this.updateRezepte();
    },
    "$store.state.sort": function () {
      this.sort = this.$store.state.sort;
      this.page = 1;
      this.updateRezepte();
    },
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.count / this.itemsPerPage);
    },
  },
  methods: {
    clear() {
      this.$store.commit("changeStichwort", "");
    },
    clearFilter() {
      this.tags = [];
      this.$store.commit("changeTags", this.tags);
    },
    updateKeyword() {
      if (this.keyword) {
        this.keyword = this.keyword.trim();
      }
      this.$store.commit("changeStichwort", this.keyword);
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) {
        this.page += 1;
        this.$store.commit("changePage", this.page);
        this.updateRezepte();
      }
      window. scrollTo({ top: 0, behavior: "smooth" });
    },
    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.$store.commit("changePage", this.page);
        this.updateRezepte();
      }
      window. scrollTo({ top: 0, behavior: "smooth" });
    },
    removeChip(item) {
      var tagsLocal = this.tags;
      const index = tagsLocal.indexOf(item);
      tagsLocal.splice(index, 1);
      this.$store.commit("changeTags", tagsLocal);
      this.updateRezepte();
    },
    updateRezepte() {
      var query = this.tags.map((tag) => `gerichttyp[]=${tag}`).join("&");

      if (this.keyword) {
        if (query) {
          query = query + "&stichwort=" + this.keyword;
        } else {
          query = "stichwort=" + this.keyword;
        }
      }

      if (query) {
        query =
          query +
          "&sort=" +
          this.sort +
          "&page=" +
          this.page +
          "&itemsPerPage=" +
          this.itemsPerPage;
      } else {
        query =
          query +
          "sort=" +
          this.sort +
          "&page=" +
          this.page +
          "&itemsPerPage=" +
          this.itemsPerPage;
      }
      this.RezepteGefiltert(query);
    },
    RezepteGefiltert(query) {
      http.get(`/rezept?${query}`).then((response) => {
        this.rezepte = response.data.recipes;
        this.count = response.data.numberOfRecipes;
      });
    },
    getAllGerichttypen() {
      http.get("/gerichttyp").then((response) => {
        this.tagMap = new Map(response.data.map(a => [a.id, a.Name]));
      });
    },
  },
  async created() {
    this.getAllGerichttypen();
    this.keyword = this.$store.getters.stichwort;
    this.page = this.$store.getters.page;
    this.sort = this.$store.getters.sort;
    this.itemsPerPage = this.$store.getters.itemsPerPage;
    this.updateRezepte();
  },
};
</script>