<template>
  <div>
    <Header />
    <v-main style="padding-bottom: 9rem" >
      <v-container>
        <NeuesKochwissen />
      </v-container>
    </v-main>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import NeuesKochwissen from "../components/NeuesKochwissen.vue";
export default {
  name: "Rezepte",
  components: {
    Header,
    Footer,
    NeuesKochwissen
  },
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  },
};
</script>