<template>
  <div>
    <v-data-iterator
      v-if="bilder.length > 1 || (login && bilder.length > 0)"
      :items="bilder"
      :items-per-page="itemsPerPage"
      :page="page"
      hide-default-footer
      class="mt-5"
    >
      <template v-slot:default="{ items }">
        <v-row>
          <v-col
            v-for="item in items"
            :key="item.Name"
            cols="12"
            sm="6"
            md="6"
            lg="4"
          >
            <v-card tile>
              <v-img
                @click="imageClicked(item)"
                :src="'/bild/' + item.id + '/thumb'"
              ></v-img>
            </v-card>
          </v-col>
        </v-row>
      </template>

      <template v-slot:footer>
        <v-row class="mt-2" align="center" justify="center">
          <v-menu offset-y>
            <v-list>
              <v-list-item
                v-for="(number, index) in itemsPerPage"
                :key="index"
                @click="updateItemsPerPage(number)"
              >
                <v-list-item-title>{{ number }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-spacer></v-spacer>

          <span v-if="numberOfPages > 1" class="mr-4 grey--text">
            {{ page }}/{{ numberOfPages }}
          </span>
          <v-btn
            v-if="numberOfPages > 1 && page != 1"
            icon
            small
            class="mr-1"
            @click="formerPage"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            v-if="numberOfPages > 1 && page != numberOfPages"
            icon
            small
            class="ml-1"
            @click="nextPage"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-iterator>

    <v-dialog v-model="showImage" width="1000">
      <v-card>
        <v-card-actions>
          <v-btn icon v-if="login" @click="deleteDialog = true">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-checkbox
            v-if="login"
            v-model="image.isTitleImage"
            label="Titelbild"
            v-on:change="updateImage"
          ></v-checkbox>
          <v-spacer></v-spacer>
          <v-btn icon @click="showImage = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-img v-if="image" :src="'/bild/' + image.id + '/thumb'"></v-img>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" width="300">
      <v-card>
        <v-card-title> Wirklich löschen? </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="deleteDialog = false"> Nein </v-btn>
          <v-btn text @click="deleteImage"> Ja </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import http from "../http-common";
export default {
  name: "SingleRecipeImages",
  props: ["bilder", "rezept"],
  data() {
    return {
      login: "",
      deleteDialog: false,
      imageOpen: false,
      itemsPerPage: 6,
      page: 1,
      showImage: false,
      image: 0,
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.bilder.length / this.itemsPerPage);
    },
  },
  methods: {
    checkLogin() {
      http.get(`/auth/user`).then((response) => {
        if ("Meisterkoch" == response.data) {
          this.login = response.data;
        }
      });
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    imageClicked(item) {
      this.image = item;
      this.showImage = true;
    },
    updateImage() {
      http.put(`/bild/${this.image.id}`, this.image).then(() => {
        this.dialog2 = false;
        this.edit = false;
        this.showImage = false;
        this.$emit("ladeBilder");
      });
    },
    deleteImage() {
      http.delete(`/bild/${this.image.id}`).then(() => {
        this.edit = false;
        this.deleteDialog = false;
        this.showImage = false;
        this.$emit("ladeBilder");
      });
    },
  },
  mounted() {
    this.checkLogin();
  },
};
</script>