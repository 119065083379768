<template>
  <div>
    <Header />

    <NavigationDrawer
      v-if="$vuetify.breakpoint.mdAndDown"
      :toggle="drawer"
      style="padding-bottom: 2rem"
    />

    <v-main style="padding-bottom: 9rem">
      <v-container class="mb-10" fluid>
        <v-row v-if="$vuetify.breakpoint.smAndDown">
          <Topbar/>
        </v-row>
        <v-row>
          <v-col cols="4" lg="3" md="3" v-if="$vuetify.breakpoint.mdAndUp">
            <RecipeFilter />
          </v-col>
          <v-col cols="12" lg="7" md="6">
            <RezepteAnzeigen/>
          </v-col>
          <v-col cols="4" lg="2" md="3" v-if="$vuetify.breakpoint.mdAndUp">
            <Sidebar/>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <Footer />
  </div>
</template>

<script>
import NavigationDrawer from "../components/NavigationDrawer.vue";
import RezepteAnzeigen from "../components/RezepteAnzeigen.vue";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import RecipeFilter from "../components/RecipeFilter.vue";
import Sidebar from "../components/Sidebar.vue";
import Topbar from "../components/Topbar.vue";
export default {
  name: "AllRecipes",
  components: {
    NavigationDrawer,
    RezepteAnzeigen,
    Header,
    Footer,
    RecipeFilter,
    Sidebar,
    Topbar
  },
  data() {
    return {
      drawer: false,
    };
  },
  watch: {
    "$store.state.drawer": function () {
      this.drawer = this.$store.state.drawer;
    },
  },
  methods: {},
};
</script>