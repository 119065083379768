<template>
  <div class="mx-6 ml-10 mt-5">
    <BasicFilter />
  </div>
</template>

<script>
import BasicFilter from "./BasicFilter.vue";
export default {
  name: "RecipeFilter",
  components: {
    BasicFilter,
  },
};
</script>