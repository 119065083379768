<template>
  <div>
    <div v-if="kochwissen">
      <v-speed-dial
        v-model="fab"
        fixed
        bottom
        right
        v-if="login"
        elevation="5"
        direction="top"
        transition="slide-y-reverse-transition"
      >
        <template v-slot:activator>
          <v-btn :x-large="$vuetify.breakpoint.lgAndUp" v-model="fab" color="primary" dark fab>
            <v-icon v-if="fab"> mdi-close </v-icon>
            <v-icon v-else> mdi-menu </v-icon>
          </v-btn>
        </template>
        <v-btn
          fab
          dark
          small
          color="green"
          @click="$router.push('/HowTo/new?HowToId=' + kochwissen.id)"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn @click="deleteDialog = true" fab dark small color="red">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-speed-dial>

      <v-row class="pb-10 pt-10 d-flex justify-center">
        <h1 style="word-break: normal; text-align: center">
          {{ kochwissen.Name }}
        </h1>
      </v-row>
      <v-col cols="12" md="9" sm="12" lg="9">
        <div v-html="kochwissen.Inhalt"></div>
      </v-col>
    </div>

    <v-dialog v-model="deleteDialog" width="400">
        <v-card>
          <v-card-title> Wirklich löschen? </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="deleteDialog = false">
              Nein
            </v-btn>
            <v-btn color="primary" text @click="deleteHowto"> Ja </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
import http from "../http-common";
export default {
  name: "EinzelnesKochwissen",
  data() {
    return {
      kochwissen: null,
      login: "",
      fab: false,
      deleteDialog: false,
    };
  },
  methods: {
    checkLogin() {
      http.get(`/auth/user`).then((response) => {
        if ("Meisterkoch" == response.data) {
          this.login = response.data;
        }
      });
    },
    ladeKochwissen() {
      http.get(`/kochwissen/${this.$route.params.id}`).then((res) => {
        this.kochwissen = res.data;
        document.title = this.kochwissen.Name;
        //damit lastSeen geupdated wird
        http.put(`/kochwissen/${this.$route.params.id}`, res.data);
      });
    },
    deleteHowto() {
      this.deleteDialog = false;
      if (this.kochwissen.id != null) {
        http
          .delete(`/kochwissen/${this.kochwissen.id}`)
          .then(() => {
            this.$router.push("/HowTo");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
  mounted() {
    this.ladeKochwissen();
    this.checkLogin();
  },
};
</script>