<template>
  <div>
    <v-row class="d-flex align-center">
      <v-col cols="10">
        <v-select
          class="mb-1"
          append-icon=""
          v-model="currentSort"
          flat
          hide-details
          :items="sorten"
          @change="sort()"
          label="Sortierung"
        ></v-select>
      </v-col>
      <v-col cols="2">
        <v-btn class="mt-3" icon @click="changeSortArrow">
          <v-icon v-if="sortArrow"> mdi-arrow-up </v-icon>
          <v-icon v-else> mdi-arrow-down </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="10">
        <v-select
          append-icon=""
          v-model="itemsPerPage"
          flat
          hide-details
          :items="itemsPerPageArray"
          @change="updateItemsPerPage()"
          label="Rezepte pro Seite"
        ></v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="10">
        <v-select
          class="mb-6"
          append-icon=""
          v-model="currentRegion"
          flat
          clearable
          hide-details
          :items="regionKeys"
          @change="updateRegion()"
          label="Region"
        ></v-select>
      </v-col>
    </v-row>

    <FilterButtons />

  </div>
</template>

<script>
import http from "../http-common";
import { getCurrentSort, getCurrentArrow, getRealSort } from "../functions";
import FilterButtons from "@/components/FilterButtons.vue";
export default {
  name: "BasicFilter",
  components: {
    FilterButtons,
  },
  data() {
    return {
      itemsPerPageArray: [9, 12, 20, 21, 50, 100, 200],
      itemsPerPage: 0,
      sorten: ["Name", "Erstelldatum", "Zuletzt zubereitet"],
      currentSort: "",
      currentRegion: "",
      oldRegion: "",
      regions: [],
      realSort: "",
      sortArrow: true,
      selectedTags: []
    };
  },
  computed: {
    regionMap() {
      return new Map(this.regions);
    },
    regionKeys() {
      return Array.from(this.regionMap.keys());
    }
  },
  watch: {
    "$store.state.tags": function () {
      this.selectedTags = this.$store.state.tags;
      if (this.$store.state.tags.length < 1) {
        this.currentRegion = "";
        this.$store.commit("changeCurrentRegion", this.currentRegion);
        this.oldRegion = "";
      }
    },
    "$store.state.sort": function () {
      this.realSort = this.$store.state.sort;
      this.currentSort = getCurrentSort(this.realSort);
      this.sortArrow = getCurrentArrow(this.realSort);
      this.sort();
    },
  },
  methods: {
    changeSortArrow() {
      this.sortArrow = !this.sortArrow;
      this.sort();
    },
    sort() {
      this.realSort = getRealSort(this.currentSort, this.sortArrow);
      this.$store.commit("changeSort", this.realSort);
    },
    localUpdate(id) {
      if (this.selectedTags.includes(id)) {
        const index = this.selectedTags.indexOf(id);
        this.selectedTags.splice(index, 1);
        this.updateTags();
      } else {
        this.selectedTags.push(id);
        this.updateTags();
      }
    },
    updateTags() {
      this.$store.commit("changeTags", this.selectedTags);
    },
    updateItemsPerPage() {
      this.$store.commit("changeItemsPerPage", this.itemsPerPage);
    },
    updateRegion() {
      this.$store.commit("changeCurrentRegion", this.currentRegion);
      if (this.oldRegion) {
        const index = this.selectedTags.indexOf(
          this.regionMap.get(this.oldRegion)
        );
        this.selectedTags.splice(index, 1);
      }
      if (this.currentRegion) {
        this.selectedTags.push(this.regionMap.get(this.currentRegion));
        this.oldRegion = this.currentRegion;
      } else {
        this.oldRegion = "";
      }
    },
    getAllGerichttypen() {
      http.get("/gerichttyp").then((response) => {
        this.regions = response.data.filter(a => a.Art == 3).map(a => [a.Name, a.id]);
      });
    },
  },
  async created() {
    this.selectedTags = this.$store.getters.tags;
    this.realSort = this.$store.getters.sort;
    this.itemsPerPage = this.$store.getters.itemsPerPage;
    this.currentSort = getCurrentSort(this.realSort);
    this.sortArrow = getCurrentArrow(this.realSort);
    this.currentRegion = this.$store.getters.currentRegion;
    this.getAllGerichttypen();
  },
};
</script>