<template>
  <div>
    <v-card
    class="mx-auto my-8"
    flat
    max-width="344"
  >
      <div class="d-flex justify-center" >
      <v-avatar size="150">
            <v-img
              alt="John"
              :src="require('../assets/portrait.jpg')"
            ></v-img>
          </v-avatar>
      </div>
      <v-card-title>
        Hi, ich bin Kristina!
      </v-card-title>
      <v-card-subtitle>
        Willkommen auf meinem Food-Blog. Hier zeige ich euch meine größte Leidenschaft: das Kochen. Lasst euch gerne von den vielen Rezepten aus aller Welt inspirieren!
      </v-card-subtitle>
  </v-card>

  <v-card
        href="https://www.instagram.com/salzigundkandiert/"
        target="_blank"
     flat
   class="mx-auto" max-width="344">
    <div class="d-flex justify-center">
      <v-icon size="50">
        mdi-instagram
      </v-icon>
    </div>
    <v-card-title>
        Finde mich auf Instagram!
      </v-card-title>
      <v-img
      class="mx-auto"
      height="200"
      :src="require('../assets/insta.jpg')"
      cover
    >
    </v-img>
  </v-card>

  <v-card
        href="https://at.pinterest.com/rolsingkristina/"
        target="_blank"
     flat
   class="mx-auto my-8" max-width="344">
    <div class="d-flex justify-center">
      <v-icon color="red" size="50">
        mdi-pinterest
      </v-icon>
    </div>
    <v-card-title>
        Finde mich auf Pinterest!
      </v-card-title>
      <v-img
      class="mx-auto"
      height="220"
      :src="require('../assets/pint.png')"
      cover
    >
    </v-img>
  </v-card>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  data() {
    return {
      
    };
  },
  methods: {
    
  },
  mounted() {
    
  },
};
</script>

<style>
.mdi-instagram {
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.mdi-pinterest {
  color: #d6249f
}
</style>