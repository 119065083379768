<template>
  <v-dialog v-model="show" width="500">
    <v-form ref="form" lazy-validation>
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Einloggen
          <v-spacer></v-spacer>
          <v-btn icon color="primary" text @click.stop="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="username"
            label="Benutzername"
            :type="'text'"
            clearable
          ></v-text-field>
          <v-text-field
            :rules="[rules.required]"
            :type="'password'"
            label="Passwort"
            v-model="pas"
          ></v-text-field>
          <p>{{ loginMassage }}</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" text @click="logout"> Log out </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            type="submit"
            color="primary"
            text
            @keyup.enter="login"
            @click="login"
          >
            Log in
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import http from "../http-common";
export default {
  name: "Login",
  props: ["visible"],
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  data() {
    return {
      proofLogin: "",
      pas: "",
      username: "",
      loginMassage: "",
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  methods: {
    login() {
      http
        .post("/auth/login", {
          username: this.username,
          password: this.pas,
        })
        .then(() => {
          this.username = "";
          this.pas = "";
          this.showLogin = false;
          this.$router.go(this.$router.currentRoute);
        })
        .catch(() => {
          this.loginMassage = "Login nicht erfolgreich";
        });
    },
    logout() {
      http
        .post("/auth/logout")
        .then(() => {
          this.$router.go(this.$router.currentRoute);
        })
        .catch(() => {
          this.loginMassage = "Logout nicht erfolgreich";
        });
    },
  },
};
</script>
