<template>
  <v-navigation-drawer
    v-model="drawer"
    width="340"
    app
    right
    fixed
  >
    <v-sheet color="white" class="pa-6 pl-10">
      <BasicFilter/>
    </v-sheet>
  </v-navigation-drawer>
</template>

<script>
import BasicFilter from "./BasicFilter.vue";
export default {
  name: "NavigationDrawer",
  components: {
    BasicFilter,
  },
  props: ["toggle"],
  watch: {
    toggle(value) {
      if (value) {
        this.drawer = true;
      }
    },
    drawer(value) {
      if (!value) {
        this.$store.commit('changeDrawer', false)
      }
    },
  },
  data() {
    return {
      drawer: false,
    };
  },
};
</script>